import LoadCurveHeaders from "../graph/LoadCurveHeaders";
import OpexLabels from "../graph/OpexLabels";
import PdfElements from "../graph/PdfElements";
import BusinessPlan from "../files/BusinessPlan";
import EnergeticMix from "../files/EnergeticMix";
import { en_Hypothesis } from "../files/Hypothesis";
import { en_tips } from "../tips/TipsTranslation";
import { en_technologies } from "../technologies/TechnologiesTranslation";
import { en_captions } from "../captions/CaptionsTranslation";
import { en_cta } from "../cta/ctaTranslations";
import { en_notifications } from "../notifications/NotificationsTranslations";
export default {
  translations: {
    email: "Email",
    newEmail: "New email",
    checkEmail: "Check the new email",
    color: "Color",
    deleteProjects:
      "You are about to delete {{count}} projects, are you sure ?",
    warningDelete: "⚠️ This action is irreversible !",
    price: "Prices",
    fromYear: "From year number...",
    energyInflationIs: "Energy inflation will be of ... (%/year)",
    //Mois de l'année abrégés
    Jan: "Jan",
    Fev: "Feb",
    Mar: "Mar",
    Avr: "Apr",
    Mai: "May",
    Juin: "Jun",
    Jul: "Jul",
    Aout: "Aug",
    Sep: "Sep",
    Oct: "Oct",
    Nov: "Nov",
    Dec: "Dec",
    from: "From",
    to: "to",
    noMonthError: "Please select at least a month",
    //Mois forme pleine :
    january: "janvier",
    february: "février",
    march: "mars",
    april: "avril",
    may: "mai",
    june: "juin",
    july: "juillet",
    august: "aout",
    septembre: "septembre",
    september: "septembre",
    october: "october",
    november: "november",
    december: "décembre",
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",

    // reverse list ,
    janvier: "january",
    février: "february",
    mars: "march",
    avril: "april",
    mai: "may",
    juin: "june",
    juillet: "july",
    août: "august",
    octobre: "october",
    novembre: "november",
    décembre: "december",
    //jours de la semaine
    dimanche: "sunday",
    lundi: "monday",
    mardi: "tuesday",
    mercredi: "wednesday",
    jeudi: "thursday",
    vendredi: "friday",
    samedi: "saturday",
    //reverse
    sunday: "sunday",
    monday: "monday",
    tuesday: "tuesday",
    wednesday: "wednesday",
    thursday: "thursday",
    friday: "friday",
    saturday: "saturday",
    //general
    city: "City",
    // language: "Language",
    name: "Name",
    country: "Country",
    currency: {
      label: "Currency",
      symbol: "{{currency, price, currency}}",
      value: "{{value, price, USD}}",
    },
    folder: "Folder",
    address: "Address",
    description: "Description",
    cO2: "CO2 rate (g/kWh)",
    cO2Base: "RTE’s éCO2mix base",
    next: "Next",
    previous: "Previous",
    apply: "Apply",
    test: "testEN",
    day: "day",
    days: "days",
    year: "year",
    years: "years",
    month: "month",
    months: "months",
    reset: "Reset",

    applyDefaultRates: "Apply default tarifs structure",

    startTime: "start time",
    endTime: "end time",
    addARow: "Add a row",
    export: "Export",
    reloadPage: "Reset page layout",
    exportToPDF: "Export to PDF",
    downloadPDF: "Download PDF",
    downloadGraphics: "Download graphics",

    elementsToInclude: "Elements to include in the PDF file",
    generalElements: "General elements",
    scenarioSpecificElements: "Scenario-specific elements",
    yes: "Yes",
    no: "No",
    modify: "Modify",
    duplicate: "Duplicate",
    delete: "Delete",
    save: "Save",
    cancel: "Cancel",
    close: "Close",
    saveAndClose: "Save and close",
    mustBeGreaterThan: "Must be greater than ",
    mustBeInferiorTo: "Must be inferior to ",
    mustBeGreaterThanPreviousRow: "Must be greater than the previous row",

    mustBePositive: "Must be positive",

    "%/year": "%/year",
    "/year": "/year",
    "per year": "per year",

    advancedConfiguration: "Advanced configuration",

    between: "Between",
    power: "Power",
    HT: "Excl. VAT",
    loading: "Loading...",
    overTheYear: "Over the year",
    upTo: "up to",
    search: "Search",
    searchAProject: "Search a project",

    hour: "hour",
    //
    analysis: "Analysis",
    scenarios: "Scenarios",
    optimization: "Optimization",
    existingInstallation: "Existing installation",
    admin: "Admin",
    help: "Help",
    connectedAs: "Connected as",
    myAccount: "My account",
    mySettings: "Settings",
    myContactDetails: "Profile and preferences",
    myCredentials: "My credentials",
    myPlan: "My plan",
    myPrivacy: "Privacy",
    termsOfService: "Terms of Service",
    myCustomization: "Customisation",

    logOut: "Log out",
    home: "Home",
    welcome: "Welcome",
    newProject: "New project",
    myProjects: "My projects",

    consumptionOverview: "Consumption overview",
    energyAssessment: "Energy assessment",
    chooseProjectAnalysis: "Select one of your projects to start the analysis",
    loadingData: "Analysing data...",
    fetchingProjects: "Fetching projects",
    duplicateProject: "Duplicate your project",
    analysisMayTakeUpTo20Seconds:
      "The analysis may take up to 20 seconds, please wait.",

    changeLogo: "Customise your logo on PDF exports",
    logo: "My logo",
    uploadLogo: "Upload (png/jpg)",
    logoUploaded: "Your logo has been uploaded",
    currentLogo: "Current logo",
    language: "My language",
    slot: "Slot",
    kWc: "kWp",
    //Project list
    update: "Update",
    clicking: "by clicking ",
    refresh: "here",
    settings: "Settings",
    analyze: "Analyze",

    optimize: "Optimize",
    letsCreate: "Create your first project to begin !",
    createFirstProject: "Create your first project",
    projectLimitReached: "Project limit reached",
    youCanCreate: "You can create",
    additionnalProject: "more project",
    additionnalProjects: "more projects",
    tokenLimitReached: "You are out of tokens",
    askForQuote: "Demander un devis",
    askForDemo: "Ask for a demo",
    updateTokenNumber: "Click here to update your token number",
    extractingData: "Gathering data, please wait...",
    completeProjectCreation: "Please complete the project's creation",
    anErrorHasOccured: "Could not connect to Enedis servers",
    faultyProject: "Projects has an error",
    shiftClick: "Shift + clic to move",
    copyOF: "Copy of",

    //My account
    defaultScenarios: "Default scenarios",
    defaultRates: "Default rates",
    selectAll: "Select all",
    unSelectAll: "Unselect all",
    newRate: "New tarifs structure",
    pleaseNameRate: "Please enter a valid name",
    // Project editor
    project: "project",
    step: "step",
    outOfPreposition: "out of",
    projectInfo: "Project information",
    consumer: "consumer",
    supplier: "supplier",
    selfConsumptionProject: "Self-consumption project",
    consumerProject: "Consumer project",
    supplierProject: "Supplier ",
    fullSellOutProject: "Full Sell-Out",
    consumer_plusProject: "Collective self-consumption project",
    movePin: "Drag and drop the pin to adjust its position",
    seeListOfCounters: "See list of counters",
    saveProject: "Save project",
    saveProjects: "Save projects",
    createProject: "Create project",
    consumptionData: "Consumption data",
    consentFile: "Consent File",
    step2Explanation:
      "So as to help you analyse, optimise and take control over your project's energy needs, we need to know your project's consumption data.",
    downloadCurrentHistory: "Consumption file",
    step2DataFormat:
      "Please respect one of the following formats with an interval on 10min to 1 hour between each step ",
    step2UseProfileData: "I want to use a consumption profile",
    consumptionProfile: "Consumption profile",
    selectProfile: "Select a profile",
    yearly: "Yearly",
    monthly: "Monthly",
    downloadConsoDataExample: "Download example",
    geocode: "GPS coordinates (ex: 45.786352, 4.8803598)",
    or: "or",

    error_notEnoughData: "Error : your file doesn't have enough data",
    cantSendNumber: "I want to use a .csv file",
    sendMyData: "Upload my consumption data (.csv)",
    dataWasSent: "File already uploaded",

    unkownAddress: "The address can't be found",
    dataSource: "Current source of data :",
    electricityMeter: "Electricity meter",
    dataFile: "uploaded file",

    inputElectricityMeter: "Electricity meter number",
    numberLengthLimit: "Your number must be 14-digit long",
    textCheckBox1:
      "The Client expressly allows Likewatt to receive information and data from Enedis regarding their load curve as well as technical and contract information available for the project, for analysis purposes, over a 12-month period.",
    textCheckBox2:
      "The Client is authorized by the Enedis client to whom this number pertains to receive information and data from Enedis regarding their load curve as well as technical and contract information for the project, for study purposes, over a 12-month period.",
    importEnedisClientConsent: "Import ENEDIS client's consent",
    consentUploaded: "Consent file uploaded",
    purchasePriceSettings: "Energy purchase rates",
    regularPrice: "Regular rates",
    customPrice: "Custom rates",
    subscriptionPrice: "Supplier power rates",
    connectingPower: "Connecting power",
    prAmount: "Amount of change in connecting power",
    energyPrice: "Supplier energy rates per kW",
    subscriptionPriceTB: "Power rates",
    energyPriceTB: "Energy rates per kW",
    subscribedPower: "Subscribed power",
    subscribedPowers: "Capacity power",
    pastSubscribedPowers: "Past subscribed powers",
    eolienPotential: "Eolien potential",
    contractType: "Contract type",
    chooseContract: "Choose a contract",
    meterOwnership: "Meter ownership",
    chooseOwnership: "Choose owner",
    voltageRange: "Voltage range",
    chooseVoltageRange: "Choose a range",
    tarifOption: "Tarifs option",
    chooseTarifOption: "Choose an option",
    CSPERate: "CSPE Rate",
    hypothesisTooltip: "Click to download the hypothesis file",
    hoursTooltip:
      " In the case of unique or non-seasonal rates, copy these values into the boxes dedicated to other time slots",
    pdlToolTip:
      "The number can be found on the bill next to 'Point de Livraison'",
    projectsCouldNotBeInitialized:
      "Following projects could not be initialized",
    projectsConfigurationIncomplete:
      "The configuration of the following projects is incomplete",
    addMyFirstCounter: "Add my first counter",
    addAnotherCounter: "Add a counter",
    includeTVA: "Include VAT in the study",
    subscribedPowerTitleModal: "Subscribed powers",
    subscribedPowerModal:
      "Your subscribed powers are set at 0kVA. Are you sure you want to save the project as is?",

    //ANALYSIS
    startDate: "Start Date",
    endDate: "End Date",
    noSharedRangeMessage:
      "Unable to analyze the collective project because your projects do not share any common period on there load curve.",
    noVATAbb: "Ex. VAT",
    withVATAbb: "w/ TTC",
    analyzing: "Analyzing ",
    fetching: "Retrieving data for project ",

    dataAreUpToDate: "Your data are up to date",
    extractLatestData: "Extract the latest consumption data now",
    PE: "Energy cost",
    PP: "Power cost",
    CO2emissions: "CO2 emissions",
    projectHasNoData: "Your project has no data",
    //EnergyBalance
    PTE: "Peak hours",
    HPH: "Peak hours high season (winter)",
    HCH: "Offpeak hours high season (winter)",
    HPB: "Peak hours low season (summer)",
    HCB: "Offpeak hours low season (summer)",
    winter: "(winter)",
    summer: "(summer)",
    selfConsumptionExplanation:
      "NB : Percentages reflect monthly self consumption rate",

    //OPTIM
    capex: "Capex",
    optimizing: "Optimizing ",
    dataGeneration10: "Generating your results may take up to 2 minutes",
    pleaseDontClose: "Please remain on this page",
    scenarioError: "Error in ",
    optimizationError: "Optimization error for scenario : ",
    modifyScenario: "Go to scenario settings",
    savingResults: "Saving results, please wait...",
    noScenariosFound: "No scenarios were found !",

    //SCENARIOS
    chooseProject: "Choose one of your projects to start",
    loadingScenarios: "Loading existing scenarios...",
    createFirstScenarioMessage: "Let's create your first scenario",
    createFirstScenarioButton: "Click to create your first scenario",
    scenarioDescription_0: "A scenario is composed of ",
    scenarioDescription_1: "the desired technologies",
    scenarioDescription_2:
      "the desired economical, ecological and technical objectives ",
    scenarioDescription_3:
      "the contractual context or participation to energy markets",
    optiwizeDescription:
      "Optiwize will optimize, for each scenario, the dimensioning of its technologies to reach the objectives and maximize the project's profitability.",
    unlimitedNumberOfScenario:
      "The number of scenarios you can create for each project is unlimited.",
    selectScenarios: "Select scenarios to calculate",
    consumerScenariosLimit:
      "You can't optimize more than two scenarios at the same time",
    supplierScenariosLimit:
      "You can't optimize several scenarios at the same time",

    optimizeScenarios: "Calculate the scenarios ",
    noMoreOptimisationToken: "You don't have enough optimization toekn, ",
    clickHereForTokens: "Click here to update your token count",
    newScenario: "New scenario",
    newDefaultScenario: "New default scenario",
    newConsumerScenario: "New consumer scenario",
    newProducerScenario: "New producer scenario",

    sortByName: "Sort by name",
    sortByDate: "Sort by date",
    scenariosToUpdateMessage: "The following scenarios are obsolete :",
    optimizeOneScenario: "Optimize this scenario",
    scenarioToUpdateMessage: "This scenario is outdated, please update it",
    warningIncompleteProject: "Please complete this project's configuration",
    lastModification: "Last modified on ",
    //SCENARIO EDITOR
    colorHelp:
      "Choose a color that will be used in every graph for this scenario",

    scenarioName: "Scenario name",
    scenarioNameWarning: "The name of the scenario can not be empty",
    scenarioCountersWarning: "Scenario counters can not be empty",
    optimizationYear: "Optimization year",
    optimizationYearWarning: "Optimization year should be greater than 0",
    projectLifespan: "Project horizon",
    projectLifespanWarning: "Please enter a lifespan for the project",
    fullConsumptionTitleModal: "Switching to total self-consumption",
    fullConsumptionModal:
      "With storage, do you want to switch to total self-consumption?",
    financialSettings: "Financial settings",
    missingSettingsWarning: "Please check and fill-in the missing settings :",
    missingInTech: "in the settings ",
    yourObjectives: "Select your targets",
    clickToAddTechnology: "Click to add a technology",
    clickToAddCounter: "Click to add a counter",
    clickToAddACICounter: "Click to add an ACI counter",
    deleteTechWarning: "Are you sure you cant to delete this technology ?",
    deleteTechWaver: "By confirming, this thecnology's data will be lost.",
    lifespan: "Lifespan",
    systemLoss: "System loss DC/AC (%)",
    productible: "Potential production",

    degradRate: "Degradation rate",

    //OBJECTIVES
    "Alimentation secours": "Min backup power",
    Autoproduction: "Min self-supply",
    "Réduction CO2": "Min CO2 reduction",
    Effacements: "Demand response",
    "Réglage de fréquence": "FCR",
    Talon: "Idle",
    Mediane: "Median",
    Totalité: "Full",
    //TECHNOLOGY DESCRIPTION
    Photovoltaïque: "Photovoltaic",

    PhotovoltaïqueDescription:
      "Transforms solar radiation to electricity using solar panels that can be installed on buildings or on the ground. They are highly modular.",
    EolienDescription:
      "Translates the wind's kinetic energy to mechanical rotation of a generator which produces electricity. The power produced with one turbine goes from few kWs up to 6 MW.",
    BatterieDescription:
      "Battery : Reversible accumulator which restores electricity from electrochemical reactions. There are different types of batteries for stationary applications: Li-ion, Li-Po, Ni-MH, Ni-Cd, Lead-acid …",
    "Groupe électrogèneDescription":
      "Used in areas that aren’t connected to the electricity grid or where uninterrupted power supply is essential. They can work with a wide range of fuels, most commonly diesel, natural gas, propane and gasoline.",
    "Pile à combustibleDescription":
      "Fuel cell : A fuel cell generates electricity through the oxydation of a reducing agent (hydrogen) on an electrode and the reduction of an oxidizing agent (such as the oxygen in the air) on another electrode.",
    ElectrolyseDescription:
      "Uses the electricity produced only by PV, Wind turbines or Fuel cells, to break water into hydrogen and oxygen in a process called electrolysis. It may be coupled to a hydrogen storage system.",
    "Production d'eau chaudeDescription":
      "Hot water production system with photovoltaic energy using an electric water heater (with a regulator) and an accumulator (thermal storage). This system allows you to replace a current gas supply.",
    "Stockage virtuelDescription":
      "A market mechanism in which the surplus energy exported to the grid is not sold but saved to be used onsite later, in exchange for subscription and transportation fees.",
    "Bornes de rechargeDescription":
      "A charging station is an infrastructure equipped with one or several charging points for electrical vehicles",

    //TECHNOLOGY TITLE
    "Configuration de l'Electrolyse et du stockage d'hydrogène":
      "Electrolysis and Hydrogen storage settings",
    techConfiguration: "{{tech}} settings",

    projectTechnologyCard: "Consumption evolution settings",
    counterCapex: "Decrease or increase in consumption?",
    distributionKey: "Distribution key",
    distributionType: "Distribution type",
    //PROJECT TECHNO
    annualGrowthRate: "Annual growth rate",
    demandMultiplier: "Demand multiplier",
    prorata: "In proportion to consumption",
    fixed: "Fixed key",
    variable: "Variable key",
    distribKeys: "Custom time key file",
    distribKeysFile: "Import custom time key file (.csv)",
    distributionKeyTitleModal: "Deletion of your distribution key file",
    distributionKeyModal:
      "Warning, if you delete a meter, your distribution key file will also be deleted.",
    //FINANCE
    actualizationRate: "Actualization rate",
    opexInflation: "Opex escalation",
    taxRate: "Tax rate",
    debtRatio: "Debt ratio",
    interestRate: "Interest rate",
    debtDuration: "Debt duration",
    depreciation: "Depreciation over lifespan",
    //PV
    maxSurfaceWarningPower:
      "Your maximum surface cannot produce the required capacity",
    maxSurfaceTooltip:
      "You can easily modify the maximum surface by unchecking the automatic calculation box",
    missingPvInput:
      "Please enter a Peak Power, Regulatory framework, Installation type, and Maximum surface",
    peakPower: "Capacity power",
    panelNumbers: "Number of panels",
    panelModel: "Panel model",
    toOptimize: "To optimize",

    regulatoryFramework: "Use case",
    surplus: "Selling surplus energy",
    totalProduction: "Selling all production",
    fullConsumption: "Full self consumption",
    aoConsumption: "French self consumption tender",
    AOVS: "Self consuming and selling surplus",

    maxSurfaceWarningAo:
      "Your maximum surface doesn't allow for a self-consumption tender",
    noTracker: "No tracker",
    monoTracker: "Mono-axial tracker",
    biTracker: "Bi-axial tracker",
    drawMapIndication: "Use the map to draw your surfaces one by one",
    maxSurface: "Total surface of panels",
    usableSurface: "Effective area",
    roofTilt: "Roof tilt",
    specificPowerFile: "Import PV power file (.csv)",
    specificConsumptionFile: "Import comsumption file (.csv)",
    changeFile: "Change file",
    powerFileTooltip:
      "Output files from SolarEdge Designer are accepted. Your file must cover a full calendar year at hourly intervals and have the following format : ",
    sellingFileTooltip:
      "Your consumption file must look like this, and span a full civil year with one hour per row : ",
    modifiedConsoData:
      "Warning ! The new file's date range must be exactly the same as the original Consumption File from project creation",
    toModify: "To edit your surfaces",
    deleteSurface: "To delete a surface, right click on it",
    bearingsTooltip:
      "Panel orientation refers to the bearing facing the panel (N-S-E-W etc...), while tilt refers to the angle of the panel relative to the ground",
    manualSetting:
      "To manually set the share and the area of your panels, uncheck the automatic calculation box (above the map) and enter the desired values",
    importTMY: "Import TMY file (.csv)",
    tmyFileTooltip: "Your TMY file should look like this : ",
    importSpotFile: "SPOT price file (.csv)",
    spotFileTooltip: "Your file should look like this : ",
    complement: "Complement",

    yieldSTC: "Efficiency at STC (%)",
    panelPeakPower: "Panel peak power (Wp)",
    installationShare: "Share of installation",

    panelTilt: "Panels tilt",
    minSelfConsumptionRateWarning: "Cannot be lower than 50%",
    panelOrientation: "Panels orientation",
    panelWidth: "Panel width",
    panelLength: "Panel length",
    panelSurface: "Panel surface",
    tiltTracker: "Tilt of rotation axis",
    tiltTrackerTooltip: "Tilt of rotation axis relative to the horizontal (°)",
    maxAngle: "Max angle",
    maxAngleTooltip:
      "Maximum rotation angle of the single-axis tracker relative to its horizontal position",
    backtracking: "Backtracking",
    backtrackingTooltip:
      "Controls whether the tracker has backtrack capability to avoid row-to-row shading",
    panelInterdistance: "Panel interdistance",
    financingType: "Financing scheme",
    Location: "Leasing",
    Investissement: "Investment",
    PPA: "PPA",
    leasingDuration: "Lease duration",

    opexDuration: "Opex duration",
    ppaDuration: "PPA duration",
    inflation: "Selling price escalation",
    sellingPriceInflation: "Selling price escalation",
    sellingPrice: "Selling price",
    aOTarif: "Price",
    spotInflation: "SPOT escalation",
    ppaInflation: "PPA escalation",
    aoBonus: "Self consumption tender bonus",
    trackingSystem: "Tracking system",

    //PPA
    ppaPrice: "Price",
    addTurpe: "Add supply tax",
    powerToCover: "Capacity to cover",
    penaltyCost: "Penalty cost",
    missingHourWarning: "Please enter a valid time",
    profile: "Profile",
    yearlyEnergyToCover: "Energy to cover each year",
    ppaConsumptionFile: "Power profile file (.csv)",
    ppaConsumptionFileTooltip: "Your file should look like this : ",

    //NETWORK
    pleaseFillHours: "Please enter a value for subscribed powers",
    energyInflation: "Purchase price escalation",
    peakHours: "Peak hours",
    offpeakHours: "Offpeak hours",
    optimizeSubscribedPowers: "Optimize subscribed powers",
    //GRP_ELEC
    powerInputWarning: "Please enter a valid Power",
    averageFuelConsumption: "Average consumption",
    fuelConsumptionUnit: "L of fuel / kWh produced",
    fuelCost: "Fuel cost",
    fuelCostInflation: "Fuel cost escalation",
    //BATTERY
    missingStorageConversionWarning:
      "Please enter valid values for Conversion and Storage",
    storage: "Storage",
    capacity: "Capacity",
    minimumSOC: "Minimum SoC",
    maximumSOC: "Maximum SoC",
    roundTripYield: "Round trip yield",
    conversion: "Conversion",
    conversionYield: "Conversion yield (%)",
    batteryDegradRate: "Battery degradation rate",
    batteryAvailability: "Battery availability",
    rate: "Rate",
    capacityMechanism: "Capacity mechanism",
    referencePrice: "Reference price",
    batterySetPointPower: "Set point power",
    //WINDMILL
    capacityPower: "Capacity power",
    windmillHeight: "Turbine height (m)",
    turbineYield: "Turbine power efficiency",
    minTurbineNumber: "Minimum turbine number",
    maxTurbineNumber: "Maximum turbine number",
    turbineNumber: "Turbine number",

    mustBeGreaterThanMaxTurbineNumber:
      "Must be higher than max number of turbine",
    mustBeGreaterThanMinTurbineNumber:
      "Must be higher than min number of turbine",
    turbineType: "Turbine type",
    minVerticalClearance: "Minimum vertical clearance",
    mustBeLesserThanWingTipHeight: "Must be lower than wing tip height",
    wingTipHeight: "Wing tip height",
    mustBeGreaterThanVerticalClearance:
      "Must be higher than vertical clearance",
    mastHeight: "Mast height",
    mastHeightWarning: "Please specify a mast height",
    fieldAvailability: "Field availability",
    shearCoefficient: "Shear coefficient",
    technicalLosses: "Technical losses",
    wakeLosses: "Wake loss",
    environmentalLosses: "Environmental losses",
    acousticLosses: "Acoustic losses",
    electricalLosses: "Electrical losses",
    otherLosses: "Other losses",
    addWtType: "Add a type of turbine",

    //FUELCELL
    h2FuelConsumptionUnit: "kg of hydrogen / kWh produced",
    h2PurchasePrice: "Hydrogen cost",
    h2PurchaseInflation: "H2 cost escalation",
    fuelCellAverageH2Consumption: "Average consumption",

    //ELECTROLYSIS
    electrolysisWarning:
      "Please enter a valid value for electrolysis power and storage capacity",
    electrolysis: "Electrolysis",
    accumulator: "Accumulateur",
    hydrogenStorage: "Hydrogen storage",
    averageConsumption: "Average consumption",
    electrolysisUnit: "kWh produced / kg H2",
    hydrogenSellingPrice: "Hydrogen selling price",
    h2SellingFile: "Import specific h2 selling file (.csv)",
    sellingFiletooltip: "Your file should look like this: ",
    // sellingPriceInflation: "Selling price escalation",

    //ECS
    ecsWarning:
      "Please enter a valid value for ECS and thermal storage capacity",
    ecs: "Hot water production",
    thermalStorage: "Thermal storage",
    ecsUnit: "kWh consumed / m³ ECS",

    thermalSellingPrice: "Gas heating cost",
    thermalSellingFile: "Import specific ECS selling file (.csv)",

    //METER
    specificMeterFile: "Import the custom time key file (.csv)",
    meterFileTooltip:
      "Your file must cover a full calendar year at hourly intervals, the number of 'meter' columns must correspond to the number of ACC meters entered and have the following format :",

    // CONFIG PROJECT
    importConsoData: "Import consumptionData (.csv)",
    automaticRenewal: {
      title: "Subscription",
      enabled: {
        description: {
          start: "Automatic renewal is  currently ",
          status: "enabled.",
        },
        button: "Disable automatic renewal",
      },
      disabled: {
        description: {
          start: "Automatic renewal is currently ",
          status: "disabled.",
        },
        button: "Enable automatic renewal",
      },
      errors: {
        request_failed:
          "An error occurred while sending your request. Please try again later.",
        already_sent:
          "Your request is already being processed. Our team is handling it.",
      },
      success: {
        mail_sent:
          "Your request has been successfully sent. Our team will respond as soon as possible.",
      },
    },
    //VIRTUAL
    vsWarningGeneral:
      "Please enter a valid value for subscription price and supply price",
    vsFixedPrice: "Fixed price",
    vsSuppliedEnergyRate: "Price on supplied energy",
    vsPvRate: "Price on photovoltaic power",
    vsMaxCapacity: "Maximum capacity",
    vsTurpeSwitch: "Add supply costs and taxes on virtual storage energy",
    unlimited: "unlimited",
    //STATIONS
    addStationType: "Add a new station type",
    numberOfStations: "Number of stations",
    usageProbability: "Probability of usage",
    drivable: "Drivable",
    addUseCase: "Add a use case",
    //RESULTS
    scenarioResults: "Results for scenario ",
    resetPageLayout: "Reset page layout",
    withdrawIndicator: "Withdrawal capacity",
    energyOnNebef: "Energy on Nebef market",
    totalCostFirstYear: "Costs and revenues breakdown",
    totalSavingsFirstYear: "Total savings of the first year breakdown",
    costsBeforeOptim: "Costs before optimization",
    costsAfterOptim: "Costs after optimization",
    detailsCostsAfterOptim: "Annual Post-Optimization costs details",
    optimizedSubscribedPowers: "Optimized subscribed powers",
    originSubscribedPowers: "Subscribed powers before optimization",
    objective: "Objective",
    reached: "Reached",
    elementsToDisplay: "Data to display",

    //CHARTS
    supplyCost: "Supplier charge",
    tax: "Taxes",
    turpe: "Transmission charge",

    network: "Grid",
    outputInputEnergy: "Energy Output",
    capacityInstalled: "Capacity",
    numberOfUnits: "Number of units",
    timeOfDay: "Time of day",
    numberOfHours: "Number of hours",
    model: "Model",
    area: "Area",
    yield: "Efficiency",
    energyConsumption: "Energy consumed",
    inKW: "in kW",
    heel: "Base load",
    max: "Max",
    lineBalanceSheetChart: "Costs projection (bills + opex - revenus)",
    vanGraph: "Net present value",
    ccf: "Cumulative Cash Flow",
    beforeOptim: "Reference setting",
    displayAll: "Show all",
    hideAll: "Hide all",
    referenceYear: "Reference year",
    powerCovered: "Power covered",
    energyCovered: "Energy covered",
    //techSummary
    suppliedConsumedEnergy: "Supplied energy",
    selfConsumptionThrottling: "Selfconsumption/Curtailment",
    surface: "Surface",
    selfConsummed: "Consummed",
    sold: "Sold",
    clipped: "Curtailed",
    clippedM: "Curtailed",
    monthlyBilling: "Monthly billing",
    totalConsumption: "Total consumption",
    includingBattery: "including battery losses ",
    includingElectrolysis: "including electrolysis consumption ",
    includingECS: "including ECS consumption ",
    includingStations: "including charging stations consumption",

    potential: "Power potential",
    techSummaryTooltip:
      "NB : Those numbers reflect a yearly projection (12 months), they may not match the numbers displayed in the Analysis report if your data do not cover a whole year.",
    selfConsumption: "Self consumption",

    //PDF
    optimReport: "Optimization report",
    projectName: "Project name",
    projectAddress: "Project address",

    yearlyCostForecast: "Yearly cost forecast",
    scenarioName: "Scenario",
    energyMix: "Energy mix",
    yearlyCost: "Yearly cost",
    powerDistribution: "Power distribution",
    ppaGraph: "PPA",

    commentary: "Comments :",
    optimizationReport: "Optimization report",
    optimizationComparaison: "Optimization comparaison",
    freeCashflow: "Free cashflow",
    //OPEX
    ...OpexLabels.en,
    //SUPPLIER LOADCURVE
    ...LoadCurveHeaders.en,

    //Scenario Table
    projectScope: "Project scope (years)",
    projectNpv: "NPV",
    financialIrr: "Financial IRR",
    projectIrr: "Project IRR",
    projectBreakEven: "Actualised Payback time (years)",
    capexAbbr: "Investment expenditures",
    triAbbr: "Internal rate of return",
    ...PdfElements.en,
    //FILE NAME
    powers10: "Year ",
    powers1: "Year 1",
    downloadData: "Download time series",

    solutions: "Energetic mix",
    downloadSolutionsFinance: "Download balance sheet",
    exportDataToXlsx: "Export data to xlsx",

    solutionsFinance: "Balance sheet",
    prettySolutions: "Energetic mix",
    ...BusinessPlan.en.consumer,
    ...BusinessPlan.en.supplier,
    ...EnergeticMix.en.consumer,
    ...EnergeticMix.en.supplier,
    filesToDownload: "Files to download",
    //MYACCOUNT
    availableProjects: "available project(s) out of ",
    selectPlan: "Select the best plan for you",
    Demo: "Demo",
    Projects: "Projects",
    Essential: "Essentiel",
    Standard: "Standard",
    Premium: "Premium",
    "12MonthAccess": "12-month access",
    projectsSimultaneously: "projects created simultaneously",
    oneUserOnly: "One user",
    unlimitedProjects: "Unlimited projects",
    unlimitedUsers: "Unlimited users",
    receiveQuote: "Ask for a quote",
    changeCredentials: "Change my credentials",
    password: "Password",
    newPassword: "New password",
    changePassword: "Change my password",
    changeInfos: "My contact details",
    firstname: "Firstname",
    lastname: "Lastname",
    code: "Code",
    phone: "Phone",
    company: "Company",
    zipCode: "Zip code",
    noLogoAdded: "No logo has been added yet.",
    //optim messages
    Lancement: "Starting",
    solved: "step 5/5",
    yearBP: "Year",
    backToScenarioPage: "Back to Scenarios page",
    backTohomePage: "Back to Homes page",
    modifyHome: "Back to Home",

    //ERROR MESSAGES
    separatorShouldBe: "The file's separator should be",
    currently: "currently",
    emptyOrIncompleteLine: "Some rows are empty or incomplete",
    andMoreLines: " row {{firstLine}} and {{lines}} more",
    wrongColumnNumber: "Incorrect column number",
    insteadOf: "instead of",
    invalidDateAtLine: "Invalid date at line",
    dateFormatMustBe: "The correct format is dd/mm/yyyy",
    noDateBefore2020: "The date can't be prior to 01/01/2020",
    invalidHourAtLine: "Invalid time at line",
    errorAtLine: "Error at line",
    useValuesInWattsNoDecimal:
      "please use values in watts without decimal points",
    useValuesWithPointForDecimal: "please use decimal points instead of commas",
    wrongCounterNumber:
      "The number of meters added does not correspond to the number of dedicated columns in the imported file.",
    numberCounterAdded: "Added meters :",
    numberCounterExpected:
      "Number of columns dedicated to meters in the file :",
    actualizationError: "Please update by clicking ",
    // use this to convert mail subject to mailto string  https://mailto.vercel.app/
    mailForConsent:
      "mailto:?body=Autorisez%20vous%20notre%20partenaire%20Likewatt%20%C3%A0%20recevoir%20d%E2%80%99Enedis%2C%20%C3%A0%20des%20fins%20d%E2%80%99%C3%A9tude%20et%20pour%20une%20dur%C3%A9e%20de%2012%20mois%20%3A%201)%20l%E2%80%99historique%20de%20la%20courbe%20de%20charge%202)%20l%E2%80%99historique%20des%20mesures%20en%20kWh%20et%203)%20les%20donn%C3%A9es%20techniques%20et%20contractuelles%20de%20votre%20site",
    ...en_Hypothesis.consumer,
    ...en_Hypothesis.supplier,
    forABetterExperience:
      "For a better experience, drag and drop this icon to your desktop to create a shortcut",
    addADesktopIcon: "drag and drop this icon to your desktop",
    asideMenuCreateShortcut: "Desktop icon",
    dragAndDropIcon:
      "Drag and drop the icon to your desktop to create a shortcut",
    orFromChrome: "Or from Chrome",
    parameters: "Parameters",
    moreTools: "More tools",
    chromeCreateShortcut: "Create shortcut...",
    //PROFILES
    RES_BASE: "Residential Base price",
    RES_HP_HC: "Residential Peak/Offpeak hours",
    PRO_BASE: "Professionnal Base price",
    PRO_HP_HC: "Professionnal Peak/Offpeak hours",
    ENT_BT_SUP: "Business >36kVa",
    ENT_HTA: "Business High tension",
    pages: {
      home: "Home",
      analyze: "Analyze",
      scenarios: "Scenarios",
      results: "Results",
      admin: "Admin",
      help: "Help",
      manage: "Manage",
    },
    results: {
      seeDetails: "See details",
      scenarioIsObsolete: "Obsolete scenario",
      timeRNA: "Payback time (years)",
      pdfLandscape: "Landscape mode",
      pdfPortrait: "Portrait mode",
      pdfZipDownload: "Download a zip containing the graphs in png format",
      optimizingProject: "Optimising your self-production project",
      reportDurationInformationACI:
        "The energies above are indicated for the period over which consumption data is only available. This period may therefore not represent a full year.",
      reportDurationInformationACC:
        "The energies above are calculated only over the common period for which consumption data for all projects are available. This period may therefore not represent a full year.",
    },
    depreciationDuration: "Depreciation duration",
    ...en_tips,
    ...en_technologies,
    whatCanIDo: "What can I do ?",

    listOfProjects: {
      Folders: "Folders",
      manageFolders: "Manage folders",
      yourProjects: "Your projects",
      createFolder: "Create folder",
      currentFolder: "Current folder",
      selectFolder: "Select folder",
      allFolders: "All folders",
      lastModified: "Last modified",
      createdAt: "Created at",
      filterbyModificationDate: "Filter by modification date",
      filterbyCreationDate: "Filter by creation date",
      rename: "Rename",
    },
    navigation: {
      toScenarios: "To Scenarios",
      toHome: "To Home",
      toResults: "To Results",
    },
    editorProject: {
      updatingScenarios: "Updating scenarios pertaining to the project",
      pricesAsCentsWarning: "Prices must be expressed in cents",
      updateSubProject:
        "Updating this setting will be applied to all subprojects.",
      includedTurpe: "The price indicated on the invoice includes delivery",
      whereCanIFindPDL: "Where do I find my counter number ?",
      restOfTheYear: "rest of the year",
      fetchingData: "Extracting data..",
      prmPopUp: {
        trigger: "Where do I find the electricity meter number ?",
        content: "",
      },
    },
    loadCurveInterface: {
      general: {
        zoomOut: "Reset zoom",
        jan: "Jan",
        feb: "Feb",
        mar: "Mar",
        apr: "Apr",
        may: "May",
        jun: "Jun",
        jul: "Jul",
        aug: "Aug",
        sep: "Sep",
        oct: "Oct",
        nov: "Nov",
        dec: "Dec",
        weekdays: {
          sunday: "Sunday",
          monday: "Monday",
          tuesday: "Tuesday",
          wednesday: "Wednesday",
          thursday: "Thursday",
          friday: "Friday",
          saturday: "Saturday",
        },
      },
    },
    homePage: {
      consentDemoWaiver:
        "In order to validate your demo license, please send us the meter owner's consent at ",
      consentFileIsMissing: "Please upload the client's proof of consent. ",
      limitDate: "Limit date",
    },
    myAccountPage: {
      customization: {
        badLogoFormat: "The logo must be a jpg or png",
      },
      dashboard: {
        connectedAs: "Connected as : ",
        myPlan: "My plan :",
      },
    },
    duplicateScenarios: "Duplication of scenarios in progress",

    consent: {
      infoConsent1: "As part of a photovoltaic study, the company",
      infoConsent2:
        " would like your consent to access the electricity consumption data of this PDL meter",
      infoConsent3: " with Enedis.",
      authorize1: "I authorize Enedis to communicate directly with the company",
      authorize2:
        " via the Optiwize software (edited by Likewatt) the data below:",
      consent1:
        "The history of measurements, in kWh, of the project (and powers reached and power exceeded)",
      consent2:
        "The history of daily index readings, in kWh, and the maximum daily power, in kVA or kWh, of the project",
      consent3:
        "The historical load curve, in step restored by Enedis, of the project",
      consent4: "Technical and contractual data available from the project",
      consentReceived: "We have received your authorization. Thank you",
      removeConsent:
        "Your authorization is valid for a period of 12 months. You can withdraw your authorization at any time by sending a message to",
      sendAutorization: "Send authorization",
      alreadyConsent: "We have already received your consent on ",
      alreadyConsent2: "Thank you for your trust",
    },
    info: {
      fetchingTimezone: "Fetching timezone...",
      disableOptimToUploadFile:
        "Please untick Optimization before uploading a solar panels production file",

      fileWontBeUsedWarning:
        "Warning : the file won't be used if 'Optimize' is ticked",
      fileInformation:
        "Files must cover the entire civil year with 1-hour intervals.",
      wtfileInformation: "Please input a peak power > 0 beforehand.",
      wtFileCoverYear:
        "Files must cover the entire civil year with 1-hour intervals.",
      pvfileInformation:
        "Please input a peak power >0 beforehand. Files must cover the entire civil year with 1-hour intervals. SolarEdge Designer csv files are accepted.",
      pdfInformation:
        "For best pdf quality, please set the application window to its full size.",
      licenceWillExpireIn:
        "Your licence will expire in {{number}} {{unit}}, Your projects will be deleted if you don't renew your licence.",
      licenceHasExpired:
        "Your licence has expired ! Your projects will be deleted if you don't renew your licence.",

      onlyOneProjectRemaining: "Warning : only one more project can be created",
      onlyNProjectsRemaining:
        "Warning : only {{number}} more projects can be created",
    },
    outOf: "on",
    analysisPage: {
      outOf: "out of",
      anErrorHasOccurred: "An error occurred in the analysis",

      pleaseContactSupport:
        "Please contact support and communicate the following analysis id ",
    },
    excel: {
      consumption: "Consumption",
      ofWhich: "dont",
    },
    mail: {
      to: "To",
      object: "Object",
      company: "Company",
      body: "Body",
      send: "Send",
      success: "Your mail has been sent ✅",
      failure: "Your mail couldn't be sent ❌",
    },
    ...en_captions,
    ...en_cta,
    ...en_notifications,
  },
};
