const getBaseURL = () => {
  switch (process.env.REACT_APP_BACK_ENV) {
    case "development":
      return "https://apidev.likewatt-infra.com/";
    case "stage":
    case "test":
      return "https://apitest.likewatt-infra.com/";

    default:
      return "https://api.likewatt-infra.com/";
  }
};

const getPdfBaseURL = () => {
  if (process.env.PDF_ENV === "LOCAL") return "http://localhost:3001/";

  switch (process.env.REACT_APP_ENV) {
    case "test":
      return "https://pdf-server-side.likewatt-infra.com/";

    default:
      return "https://pdf-server-side.likewatt-infra.com/";
  }
};

const UserStatusActionLabels = {
  activate: "activate",
  deactivate: "false",
};

export default {
  UserStatusActionLabels,
  BASE_URL: getBaseURL(),
  PDF_BASE_URL: getPdfBaseURL(),
  INIT_DATA: "sites/init-data/",
  INIT_DATA_FILE: "sites/init-data-file/",
  CONSO_DATA_DUPLICATE: "sites/site/",
  CONSO_DATA_FILTERED: "sites/reportfilefiltered/",
  ANALYSIS_FILE: "sites/analysisfile/",
  ANALYSIS_FILE_EXIST: "sites/analysisfilesexist/",
  RESET_ANALYSIS: "sites/resetanalysis/",
  HELLO: "hello/",
  OPTIMIZE: "optimize/",
  OPTIMIZE_COLLECTIVE_CONSUMPTION: "optimize/collective_consumption/",
  PROGRESS: "optimize/",
  TRIGGER: "optimize/trigger-transfer/",
  FETCH: "fetch-measures-history-enedis/",
  CONTRACT: "enedis/contractInformation/",
  UPDATE: "enedis/report/",
  FCR: "ext/fcr_market/",
  DELETE_SCENARIO: "optimize/scenarios/",
  PROFILES: "profil/getProfileFromIndexes/",
  INIT_DATA_INDEX: "enedis/consumptionIndexes/",
  USER_ADMIN: "usersAdmin/user",
  USER_CREDENTIALS: "usersAdmin/user/credentials",
  DELETE_SUBORDINATES: "usersAdmin/manager/user",
  TOOGLE_USER_ACTIVIY: "usersAdmin/user/status/",
  ANALYSIS: "sites/analysis/",
  ANALYSIS_PROGRESS: "sites/",
  REPORT_V3: "enedis/v3/report/", // :siteId/:type/
  PDF: {
    ANALYSIS: {
      INDIVIDUAL: "pdf/analysis/individual",
      COLLECTIVE: "pdf/analysis/collective",
    },
    OPTIM: {
      GLOBAL: "pdf/optimization/general/consumer",
      SCENARIO: "pdf/optimization/scenario/consumer",
    },
  },
  DELETE_USER: "auth/deleteUser",
  PARAMS_BUILDER: "optimize/optim-params/",
  DEFAULT_VALUES: "admin/defaultValues/",
};
