import api from "./../constants/Api";
import axios from "axios";
import DataForm from "form-data";
import Auth from "./Auth";
import firebase from "firebase";
import AuthConstants from "../constants/Auth";
import SiteHelpers from "../helpers/Site";
import SiteConstants from "../constants/Site";
const {
  BASE_URL,
  INIT_DATA_FILE, //Used for CSV
  TRIGGER,
  OPTIMIZE,
  OPTIMIZE_COLLECTIVE_CONSUMPTION,
  INIT_DATA, //Used for TMY
  FETCH, //Unused
  CONTRACT,
  UPDATE, //Used to create report from PRM
  FCR,
  ANALYSIS,
  ANALYSIS_PROGRESS,
  DELETE_SCENARIO,
  PROFILES, //Profiles
  INIT_DATA_INDEX, //Mesures
  USER_ADMIN,
  USER_CREDENTIALS,
  DELETE_SUBORDINATES,
  TOOGLE_USER_ACTIVIY,
  REPORT_V3,
  PARAMS_BUILDER, //To test optim_params
  DEFAULT_VALUES,
} = api;

class ApiService {
  getToken = async () => firebase.auth().currentUser.getIdToken();
  getTimeZone = async ({ lat, lng }) => {
    const token = await this.getToken();
    console.log({ token });
    return await axios
      .post(
        AuthConstants.URL_TIMEZONE,
        {
          GEOCODE: [lat, lng],
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `${token}`,
          },
        },
      )
      .then((res) => {
        return Promise.resolve(res.data.timezone);
      })
      .catch((e) => Promise.reject(e));
  };
  analyze = async (idSite) => {
    const TOKEN = await this.getToken();
    return await axios({
      method: "post",
      url: `${BASE_URL}${ANALYSIS}${idSite}`,
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => Promise.reject(e));
  };
  analyzeProgress = async (analysisId) => {
    const TOKEN = await this.getToken();
    return await axios({
      method: "get",
      url: `${BASE_URL}${ANALYSIS_PROGRESS}${analysisId}`,
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => Promise.reject(e));
  };
  initTMY = async (idSite, [lat, long], country = "fr") => {
    const TOKEN = await this.getToken();
    return await axios({
      method: "post",
      url: `${BASE_URL}${INIT_DATA}${idSite}?lat=${lat}&long=${long}&country=${country}`,
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then((res) => {
        return res;
      })
      .catch((e) => Promise.reject(e.response));
  };
  updateData = async (idSite) => {
    const TOKEN = await this.getToken();

    return await axios({
      method: "patch",
      url: `${BASE_URL}${UPDATE}${idSite}`,
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log("RES update  =>", e.response.data);
        return e.response;
      });
  };

  initDataDailyIndex = async (idSite) => {
    // :siteId/:type/
    // idSite
    //type : ["COURBE" , "ENERGIE" , "INDEX" , "PMAX"]
    const TOKEN = await this.getToken();
    return await axios({
      method: "post",
      url: `${BASE_URL}${REPORT_V3}${idSite}/INDEX/`,
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then((res) => {
        return res;
      })
      .catch((e) => e.response);
  };

  initDataIndex = async (idSite, isMonthlyIndex = true) => {
    const TOKEN = await this.getToken();
    if (!isMonthlyIndex) return this.initDataDailyIndex(idSite);
    return await axios({
      method: "get",
      url: `${BASE_URL}${INIT_DATA_INDEX}${idSite}`,
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then((res) => {
        return res;
      })
      .catch((e) => e.response);
  };

  forceUpdateData = async (idSite) => {
    const TOKEN = await this.getToken();
    //type : ["COURBE" , "ENERGIE" , "INDEX" , "PMAX"]

    console.log("FORCE UPDATE CALLED");
    return axios({
      method: "post",
      //   url: `${BASE_URL}${UPDATE}${idSite}`,
      url: `${BASE_URL}${REPORT_V3}${idSite}/COURBE/`,

      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then((res) => {
        return res;
      })
      .catch((e) => e.response.data);
  };

  initDataFile = async (idSite) => {
    const TOKEN = await this.getToken();

    return await axios({
      method: "post",
      url: `${BASE_URL}${INIT_DATA_FILE}${idSite}`,

      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(() => {})
      .catch((e) => Promise.reject(e.response));
  };
  getContractInfo = async (idPoint) => {
    const TOKEN = await this.getToken();

    const endpoint = `${BASE_URL}${CONTRACT}${idPoint}`;
    return await axios({
      method: "get",
      url: endpoint,

      headers: {
        Authorization: `Bearer ${TOKEN}`,
        "content-type": "multipart/form-data",
      },
    })
      .then((res) => {
        return res.data.data.points;
      })
      .catch((e) => {
        return e.response;
      });
  };
  fetchData = async (idPoint) => {
    const TOKEN = await this.getToken();

    await axios({
      method: "post",
      url: `${BASE_URL}${FETCH}${idPoint}`,
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then((res) => {
        console.log("res FetchEnedis", res);
      })
      .catch((e) => console.log("erreur: ", e));
  };
  optimize = async (idSite, idScen, config, isCollectiveSite = false) => {
    const TOKEN = await this.getToken();

    const endpoint = `${BASE_URL}${
      isCollectiveSite ? OPTIMIZE_COLLECTIVE_CONSUMPTION : OPTIMIZE
    }${idSite}/${idScen}`;

    let file = new File([JSON.stringify(config, null, 4)], "config");
    let formdata = new DataForm();
    formdata.append("config", file, "config");

    return await axios({
      method: "post",
      url: endpoint,

      headers: {
        Authorization: `Bearer ${TOKEN}`,
        "content-type": "multipart/form-data",
      },
      data: typeof config !== "undefined" ? formdata : undefined,
    })
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((e) => Promise.reject(e.response.data));
  };
  optimizeProgress = async (uuid) => {
    const TOKEN = await this.getToken();

    const endpoint = `${BASE_URL}${OPTIMIZE}${uuid}`;
    return await axios({
      method: "get",
      url: endpoint,

      headers: {
        Authorization: `Bearer ${TOKEN}`,
        "content-type": "multipart/form-data",
      },
    })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log("data", e.response.data);
        // console.log("status", e.response.status);
        // console.log("headers", e.response.headers);
        return Promise.reject(e.response.data);
      });
  };
  trigger = async (siteId, scenId) => {
    const TOKEN = await this.getToken();

    await axios({
      method: "post",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
      url: `${BASE_URL}${TRIGGER}${siteId}/${scenId}`,
    })
      .then((res) => {
        console.log("res trigger", res);
      })
      .catch((e) => console.log("erreur :", e));
  };
  getFcr = async (start, end) => {
    return await axios({
      method: "get",
      url: `${BASE_URL}${FCR}${start}/${end}`,
    })
      .then((res) => {
        return res;
      })
      .catch((e) => e);
  };
  deleteScenarioFiles = async (idSite, idScen, fileName) => {
    const TOKEN = await this.getToken();

    return await axios({
      method: "delete",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
      url: `${BASE_URL}${DELETE_SCENARIO}${idSite}/${idScen}/${fileName}`,
    })
      .then((res) => {
        console.log(`Scenario ${idScen} was deleted`);
        return res;
      })
      .catch((e) => e);
  };
  createConsoDataFromProfile = async (site) => {
    const consumptionIndexes = SiteHelpers.handleConsumptionIndexesForApi(
      site.consumptionIndexes,
    ).reverse();
    console.log({ consumptionIndexes });
    return await axios({
      method: "post",
      url: `${BASE_URL}${PROFILES}${site.id}`,
      data: consumptionIndexes,
    })
      .then((res) => {
        return res;
      })
      .catch((e) => e);
  };

  createUser = async (userData) => {
    const TOKEN = await this.getToken();
    return await axios({
      method: "post",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
      url: `${BASE_URL}${USER_ADMIN}`,
      data: userData,
    });
  };

  updateUser = async (userData) => {
    const TOKEN = await this.getToken();
    return await axios({
      method: "put",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
      url: `${BASE_URL}${USER_ADMIN}`,
      data: userData,
    });
  };

  updateUserCredentials = async (userData) => {
    const TOKEN = await this.getToken();
    return await axios({
      method: "put",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
      url: `${BASE_URL}${USER_CREDENTIALS}`,
      data: userData,
    });
  };

  deleteSubordinate = async (subordinate) => {
    const TOKEN = await this.getToken();
    return await axios({
      method: "delete",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
      url: `${BASE_URL}${DELETE_SUBORDINATES}`,
      data: subordinate,
    });
  };

  toggleUserActivity = async (user, action) => {
    const TOKEN = await this.getToken();
    return await axios({
      method: "put",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
      url: `${BASE_URL}${TOOGLE_USER_ACTIVIY}${action}`,
      data: user,
    });
  };

  buildOptimParams = async (site, scenario) => {
    const TOKEN = await this.getToken();
    return await axios({
      method: "post",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
      url: `${BASE_URL}${PARAMS_BUILDER}`,
      data: { site, scenario },
    });
  };

  /**
   *
   * @param {*} type Type of defaultValues to get CONSUMER
   * @returns json
   */
  getDefaultValues = async (type) => {
    const TOKEN = await this.getToken();
    let res;
    try {
      res = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
        url: `${BASE_URL}${DEFAULT_VALUES}${type}`,
      });
    } catch (error) {
      return Promise.reject("Could not get default Values :", error);
    }
    return res.data.data;
  };

  uploadFile = async (file, path) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("path", path);
    try {
      return await axios({
        method: "post",
        url: `${BASE_URL}files/upload`,
        data: formData,
      });
    } catch (e) {
      return Promise.reject("Could not upload file :", e);
    }
  };

  deleteFile = async (path) => {
    try {
      return await axios({
        method: "delete",
        url: `${BASE_URL}files/`,
        params: { path: path },
      });
    } catch (e) {
      return Promise.reject("Could not delete file :", e);
    }
  };

  getFile = async (path) => {
    try {
      return await axios({
        method: "get",
        url: `${BASE_URL}files/`,
        params: { path: path },
      });
    } catch (e) {
      return Promise.reject("Could not get file :", e);
    }
  };

  getFileUrl = async (path) => {
    return `${BASE_URL}files/?path=${path}`;
  };
}

export default new ApiService();
