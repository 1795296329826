import React from "react";
import { Link } from "react-router-dom";
const Terms = () => {
  return (
    <div id="terms" className="terms-container">
      <Link
        className="link-container"
        style={{
          width: "100%",
          textAlign: "center",
          display: "block",
          marginBottom: "2%",
        }}
        to={{
          pathname: "/",
        }}
      >
        Retour à l'accueil
      </Link>
      <div className="terms">
        {/* This WEB APP could be used to transform Word document to HTML */}
        {/* https://wordhtml.com/ */}
        <p>(Mises à jour le 14 février 2025)</p>
        <h3>
          <strong>CONDITIONS GÉNÉRALES D'UTILISATION (CGU)</strong>
        </h3>
        <h5>1. Définitions</h5>
        <p>
          « Bon de Commande » désigne tout devis, proposition commerciale, ou
          bon de commande signé par le Client et approuvé par Likewatt, par
          lequel le Client accepte de souscrire à un Abonnement ou commande des
          Prestations au titre des présentes. 
        </p>
        <p>
          « Client(s) » désigne toute personne physique ou morale ayant signé un
          Bon de Commande avec Likewatt.
        </p>
        <p>
          « Service(s) » désigne toute fonctionnalité et service réalisés par la
          Plateforme, comprenant la collecte et l’analyse de données, la
          modélisation et la simulation de scénarios, le calcul de
          dimensionnement, la génération des rapports, etc. 
        </p>
        <p>
          « Plateforme » désigne le logiciel SaaS accessible à l’adresse{" "}
          <a href="https://app.likewatt.com">
            <u>https://app.likewatt.com</u>
          </a>
          , proposant les Services aux Clients en fonction de leurs forfaits.
        </p>
        <p>
          « Prestations » désignent toutes les interventions de Likewatt
          effectuées auprès du Client au-delà de son Abonnement. Les Prestations
          peuvent porter sur l’accompagnement technique et fonctionnel, le
          paramétrage du compte du Client sur la Plateforme, la création de
          documents, le reporting, le support. La liste des Prestations n’est
          pas exhaustive. Les Prestations commandées par le Client sont
          désignées au Bon de Commande. 
        </p>
        <p>
          « Abonnement » désigne un droit d’accès à toutes ou une partie des
          fonctionnalités de la Plateforme, accordé au Client en fonction du
          forfait sélectionné dans son Bon de commande tout le long de la
          Période d’abonnement. Les caractéristiques du forfait, les
          fonctionnalités mises à disposition et le prix de l’Abonnement sont
          désignés dans le Bon de Commande. 
        </p>
        <p>
          « Durée d'abonnement » désigne la période durant laquelle l’Abonnement
          à la Plateforme de Likewatt est effectif telle qu’elle est précisée
          dans le Bon de commande.
        </p>
        <p>
          Les « Utilisateurs » sont les salariés, représentants, consultants,
          contractuels ou agents du Client autorisés à accéder à la Plateforme
          en son nom et qui ont accès au même Abonnement du Client, en utilisant
          son compte unique défini par un identifiant et un mot de passe. 
        </p>
        <p>
          Est considérée comme « Donnée » toute donnée personnelle ou technique
          renseignée ou collectée par le Client et liée au paramétrage de son
          compte ou de ses projets étudiés sur la Plateforme.
        </p>
        <p>
          « Accord » désigne le présent document qui constitue les Conditions
          Générales d’Utilisation (ci-après « les CGU ») et qui a pour objet de
          définir les modalités d’utilisation à destination du Client et de ses
          Utilisateurs.
        </p>
        <br />
        <h5>2. Objet des conditions générales</h5>
        <p>
          Les présentes CGU entrent en vigueur à leur date de mise en ligne et
          s’appliquent dès la première utilisation de la Plateforme. Elles
          constituent un accord ayant force obligatoire entre le Client et
          Likewatt. 
        </p>
        <p>
          Tout accès ou utilisation de la Plateforme suppose l’acceptation
          concomitante et sans réserve par le Client des termes des présentes
          CGU. Le Client et ses Utilisateurs s’engagent à respecter les CGU
          décrites ci-après. 
        </p>
        <p>
          Ces CGU sont accessibles à tout moment sur la Plateforme et
          prévaudront, le cas échéant, sur toute autre version ou tout autre
          document contradictoire. Le Client est invité à prendre régulièrement
          connaissance et à lire attentivement la dernière version des CGU
          applicables. Toute modification des CGU est notifiée électroniquement
          au Client qui est libre d’en refuser l’application et mettre ainsi un
          terme à son abonnement. En l’absence de refus exprès du Client, les
          CGU seront applicables dès la prochaine utilisation de la Plateforme.
        </p>
        <p>
          Si une ou plusieurs stipulations des CGU sont tenues non valides ou
          déclarées comme telles en application d’une loi, règlement ou à la
          suite d’une décision d’une juridiction compétente, elles seront
          réputées non écrites mais les autres demeureront en vigueur.
        </p>
        <br />
        <h5>3. Utilisation des Services</h5>
        <h6>a) Accès à la plateforme</h6>
        <p>
          Tout au long de la Durée d’abonnement, Likewatt donne accès au
          Client et à ses Utilisateurs, à la Plateforme et à ses Services en
          fonction du forfait sélectionné par le Client dans le Bon de commande.
        </p>
        <p>
          Likewatt se réserve le droit d’offrir un accès gratuit à toutes ou une
          partie des fonctionnalités de la Plateforme à certains utilisateurs
          sur une période limitée et de restreindre ou de bloquer cet accès à
          tout moment.
        </p>
        <p>
          Les frais de télécommunication lors de l’accès à internet et de
          l’utilisation de la Plateforme sont à la charge des Clients. Il n’y a
          aucune restriction de temps ou de durée d’utilisation de la
          Plateforme, quel que soit le profil du Client.
        </p>
        <p>
          Likewatt se réserve le droit, sans préavis ni indemnité, de changer
          les méthodes opérationnelles, les serveurs et les heures
          d’accessibilité, de fermer temporairement ou définitivement la
          Plateforme ou l’accès à un ou plusieurs Services pour effectuer une
          mise à jour ou des modifications. 
        </p>
        <p>
          De la même manière Likewatt se réserve le droit d’apporter à la
          Plateforme et à ses Services toutes les modifications et améliorations
          qu’elle jugera nécessaires ou utiles dans le cadre du bon
          fonctionnement de la Plateforme.
        </p>
        <h6>b) Exclusion de garanties et limites de responsabilités</h6>
        <p>
          Sans limiter les obligations qui leur incombent en vertu de la section
          « Utilisation des données » du présent accord, Likewatt, ainsi que ses
          filiales et agents, ne font aucune déclaration ni n'offrent aucune
          garantie en ce qui concerne l'adéquation, la fiabilité, la
          disponibilité, la rapidité, la sécurité ou l'exactitude des Services
          et des données fournis par la Plateforme.
        </p>
        <p>
          Les interfaces de programmation applicative (API) peuvent ne pas être
          disponibles à tout moment. Dans la mesure autorisée par la loi, les
          Services fournis par la Plateforme sont fournis en l'état sans
          garantie ou condition d'aucune sorte. Likewatt exclut toute garantie
          et condition de toute sorte, que ce soit expressément ou
          implicitement, en ce qui concerne les Services fournis par la
          Plateforme, y compris toutes les garanties implicites ou conditions de
          qualité marchande, d'adéquation à un usage particulier, de titre et de
          non-contrefaçon.
        </p>
        <p>
          Dans la mesure autorisée par la loi, en aucun cas, Likewatt, ainsi que
          ses filiales et agents, ne peuvent être tenus responsables des
          dommages indirects, accessoires, punitifs ou consécutifs ou bien de la
          perte de profits, de revenus, de données ou d’opportunités
          commerciales découlant de l’utilisation de la Plateforme et de ses
          Services ou découlant du présent Accord ou liés à ce dernier. 
        </p>
        <p>
          Si, nonobstant les autres conditions du présent accord, Likewatt est
          tenue comme responsable d’un dommage envers le Client découlant de
          l’utilisation de la Plateforme et de ses Services, les parties
          conviennent que la responsabilité agrégée sera limitée à un montant
          égal au total des montants que le Client a versé ou doit verser pour
          le service d'Abonnement dans les douze mois précédant l'incident ayant
          donné lieu à la réclamation ; si cette limitation ne s'applique pas au
          Client du fait qu'il n'utilise que les Services gratuits, la
          responsabilité agrégée de Likewatt sera alors limitée à cent Euros.
        </p>
        <p>
          Le Client comprend et accepte que sans son accord concernant cette
          limitation de responsabilité, il n'aura pas accès à la Plateforme.
        </p>
        <br />
        <h5>4. Utilisation des Données et Vie privée</h5>
        <h6>a) Traitement des Données des Clients</h6>
        <p>
          L’utilisation de la Plateforme par le Client implique le recueil de
          certaines Données personnelles du contact administrateur lors de
          l’inscription : nom, prénom, adresse postale, numéro de téléphone et
          adresse mail. La finalité de la collecte, du traitement et de la
          conservation de ces données est de permettre aux Utilisateurs de
          s'authentifier correctement et en toute sécurité à travers le login et
          le mot de passe choisi par l’administrateur de la licence, mais aussi
          de tenir l’administrateur informé des actualités relatives à la
          Plateforme. 
        </p>
        <p>
          Sont aussi recueillies des Données techniques pour le paramétrage des
          projets étudiés et pour la collecte des informations liées au compteur
          électrique d’une personne physique ou morale tierce, tel que le numéro
          du Point de Référencement Mesure (PRM), les données techniques et
          contractuelles, l’historique des mesures en kWh et la courbe de
          charge.
        </p>
        <p>
          Le Client est informé qu’un dispositif de stockage numérique sécurisé
          est utilisé, incluant notamment des dispositifs de pare-feu et de
          cryptage pour se prémunir de toute intrusion malveillante.
        </p>
        <p>
          Le Client est informé que les Données collectées sont conservées dans
          les bases de données de Likewatt pour la durée nécessaire à
          l’utilisation de la Plateforme par ses Utilisateurs. A compter de la
          date de suppression du compte du Client, toutes les Données associées
          seront conservées pendant une durée de 2 ans. L’Utilisateur dispose
          d’un droit d’accès, de communication, de rectification et, sous
          réserve des dispositions légales applicables, de suppression des
          Données le concernant. 
        </p>
        <p>
          Ce droit d’accès, de communication de rectification ou de suppression
          des Données s’exerce sur simple demande de la part du Client, adressée
          à Likewatt par email sur{" "}
          <a href="mailto:contact@likewatt.com">contact@likewatt.com</a> ou par
          courrier recommandé.
        </p>
        <p>
          La Plateforme se conforme aux dispositions de la loi n° 78-17 du 6
          janvier 1978 relative à l’informatique, aux fichiers et aux libertés,
          ainsi qu’à celles du Règlement européen 2016/679 du 27 avril 2016
          relatif à la protection des données personnelles et à la loi du 20
          juin 2018 n° 2018-493 relative à la protection des données
          personnelles.
        </p>
        <p>
          Likewatt maintiendra des protections administratives, physiques et
          techniques appropriées aux fins de protéger les Données des Clients.
          Likewatt respectera les engagements prévus dans les présentes
          Conditions Générales d’Utilisation et veillera à ce que son personnel,
          son groupe et chacun de ses sous-traitants respectent ses termes.
        </p>
        <p>
          Le Client est informé et consent à la collecte, le traitement et la
          conservation de ses données dans les conditions décrites ci-dessus.
        </p>
        <h6>b) Obligations du Client</h6>
        <p>
          Pour consulter et extraire les données techniques et contractuelles,
          l’historique des mesures en kWh et de la courbe de charge disponibles
          pour un Point de Référencement Mesure (PRM) ou un Point de Livraison
          (PDL) donné, le Client s’engage à disposer de l’autorisation expresse
          préalable du client d’ENEDIS référencé par le PRM ou le PDL en
          question. 
        </p>
        <p>
          Cette autorisation expresse doit explicitement inclure l’identité de
          la personne physique ou morale donnant son consentement, l’identité
          des receveurs mentionnant le Client et son partenaire Likewatt et
          l’accord à demander et à recevoir communication auprès d'Enedis des
          données techniques et contractuelles, de l’historique des mesures en
          kWh et de la courbe de charge pour des fins d'étude sur une durée de
          12 mois.
        </p>
        <p>Cette autorisation peut être obtenue :</p>
        <p>
          - En utilisant le formulaire de consentement rempli et signé par le
          client d’Enedis et dont un exemple peut être retrouvé sur ce lien :{" "}
          <a href="https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2FAutorisation%20ENEDIS.docx?alt=media&amp;token=eb9d5f92-bca5-4066-ad5b-58cebe591c19">
            https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2FAutorisation%20ENEDIS.docx?alt=media&amp;token=eb9d5f92-bca5-4066-ad5b-58cebe591c19
          </a>
        </p>
        <p>
          - Ou en renseignant les coordonnées du client d’Enedis directement sur
          le logiciel, afin qu’il reçoive un e-mail de demande de consentement
          qu’il peut valider en direct à travers un parcours digitalisé. 
        </p>
        <p>
          Le Client est informé qu’accepter des conditions au nom d’une autre
          personne physique ou morale constitue une fraude et peut engager
          sa responsabilité civile et pénale. Cette action peut être assimilée à
          une falsification de consentement ou à une usurpation d’identité,
          passible de sanctions conformément aux lois en vigueur.
        </p>
        <h6>c) Limites concernant Likewatt</h6>
        <p>
          Likewatt n'utilisera ni ne permettra à quiconque d'utiliser les
          Données du Client dans le but de communiquer avec une personne ou une
          entreprise tierce, sauf instruction contraire ou autorisation de la
          part du Client.
        </p>
        <p>
          Likewatt n'utilisera les Données du Client que pour fournir au Client
          des Prestations à sa demande ou du support dans le cadre de son
          Abonnement, et ce dans les limites permises par la loi en vigueur, par
          le présent accord et par la politique de confidentialité relative à
          l'utilisation de la Plateforme.
        </p>
        <p>
          Likewatt peut exploiter les Données techniques du Client à des fins
          d’apprentissage algorithmique pour générer des informations de manière
          globale et anonyme qui viennent appuyer certaines fonctionnalités de
          la Plateforme. Le Client consent à ce que Likewatt puisse utiliser et
          publier ces informations générées, à condition qu'elles n'incluent pas
          les Données des Clients ni ne permettent de l'identifier.
        </p>
        <h6>d) Publicité</h6>
        <p>
          Le Client accorde à Likewatt le droit d'ajouter son nom et le logo de
          son entreprise à sa liste de Clients et sur son Site web. Il peut
          toutefois demander à s'y opposer en contactant le service client.
        </p>
        <br />
        <h5>5. Propriété intellectuelle</h5>
        <p>
          Tous les éléments de propriété intellectuelle utilisés par Likewatt
          sont protégés par les lois relatives à la propriété intellectuelle et
          autres, et notamment le droit d’auteur.
        </p>
        <p>
          La Plateforme et tous ses éléments, notamment mais non limitativement,
          les codes source, les algorithmes, les modèles, les textes, les
          images, les vidéos, les photographies, les marques, les logos, les
          dénominations sociales, les noms de domaine sont la propriété
          exclusive de Likewatt.
        </p>
        <p>
          Toute reproduction ou représentation, même partielle, par quelque
          procédé que ce soit, toute requête automatisée ou non visant la
          récupération des données publiées sur cette plateforme, faites sans
          l’autorisation de Likewatt, sont illicites et constituent une
          contrefaçon conformément aux dispositions de la loi du 11 mars 1957,
          articles 40, 41 et 42 du code pénal. Tout contrevenant sera poursuivi
          devant les juridictions compétentes.
        </p>
        <br />
        <h5>6. Conditions générales de l'abonnement</h5>
        <h6>a) Durée d’abonnement et renouvellement</h6>
        <p>
          La Durée d'abonnement du Client est précisée dans son Bon de commande.
        </p>
        <p>
          Sauf mention contraire dans le Bon de commande ou en cas d’une
          résiliation de l’Abonnement par l’une ou l’autre des parties ou en cas
          de notification de non-renouvellement effectuée par le Client, le
          renouvellement de l'abonnement est automatique à l’issue de cette
          durée et s’applique pour la même Durée d'abonnement initiale. 
        </p>
        <p>
          Le renouvellement automatique de l'Abonnement est toutefois
          conditionné à la mise en place d’un mandat SEPA valide et signé par le
          Client pour le prélèvement des montants dus au titre de la période
          renouvelée. En l’absence d’un mandat SEPA valide et signé par le
          Client, la tacite reconduction ne sera pas appliquée.
        </p>
        <p>
          Le Client peut notifier son intention de ne pas renouveler son
          Abonnement sur simple demande adressée à Likewatt, par email sur{" "}
          <a href="mailto:contact@likewatt.com">contact@likewatt.com</a>, par
          courrier ou en désactivant l’option de « renouvellement automatique »
          directement sur le logiciel, au moins un (1) jour avant la fin de la
          Durée d’abonnement.
        </p>
        <p>
          Dans le cas où l’Abonnement n’est pas renouvelé à sa date
          anniversaire, les projets sauvegardés sur le compte du Client seront
          automatiquement supprimés quatorze (14) jours après la fin de la Durée
          d’abonnement.
        </p>
        <p>
          Le Client sera informé par e-mail à l’adresse renseignée dans les
          paramètres du compte, au moins trente (30) jours avant la fin de sa
          Durée d’abonnement, des termes du renouvellement et des conditions
          tarifaires applicables.
        </p>
        <h6>b) Ajustements des tarifs au renouvellement</h6>
        <p>
          Likewatt peut changer les tarifs de l’Abonnement au forfait
          sélectionné ou les caractéristiques du ce dernier. Si tel est le cas,
          le Client en sera informé au moins trente (30) jours avant la fin de
          sa Durée d’abonnement et les nouveaux tarifs seront appliqués au début
          de la Durée d’abonnement suivante. 
        </p>
        <p>
          Si le Client s'oppose à cette augmentation, il peut notifier son
          intention de ne pas renouveler son Abonnement sur simple demande
          adressée à Likewatt, par email sur{" "}
          <a href="mailto:contact@likewatt.com">contact@likewatt.com</a>, par
          courrier ou en désactivant l’option de « renouvellement automatique »
          directement sur le logiciel, au moins un (1) jour avant la fin de la
          Durée d’abonnement.
        </p>
        <h6>c) Résiliation anticipée par le Client</h6>
        <p>
          Durant la Durée d’abonnement, le Client peut résilier son Abonnement à
          tout moment de manière anticipée sur simple demande adressée à
          Likewatt par email sur{" "}
          <a href="mailto:contact@likewatt.com">contact@likewatt.com</a> ou par
          courrier, mais il est dès lors entendu que Likewatt ne procédera à
          aucun remboursement.
        </p>
        <p>
          Le Client devra verser sans délai tous les frais impayés exigibles
          jusqu'à la fin de la durée d'abonnement. Likewatt n’effectue aucun
          remboursement si le Client décide de ne plus utiliser son Abonnement
          au cours de sa Durée d’abonnement
        </p>
        <h6>d) Résiliation pour juste cause</h6>
        <p>
          Chaque partie peut mettre fin au présent Accord pour juste cause de
          même qu’à l’Abonnement :
        </p>
        <ul>
          <li>
            suivant un préavis de trente (30) jours donné à l'autre partie d'une
            violation substantielle des CGU présentes et notamment des
            conditions indiquées dans le paragraphe « Utilisation des Données et
            Vie privée », si ladite violation n'est pas résolue à l'expiration
            de ladite période, ou
          </li>
          <li>
            immédiatement si l'autre partie fait l'objet d'une demande en
            faillite ou de toute autre procédure relative à l'insolvabilité, la
            cessation d'activité, la liquidation ou la cession au bénéfice des
            créanciers. 
          </li>
        </ul>
        <p>
          Likewatt peut également mettre fin au présent Accord pour juste cause
          de même qu’à l’Abonnement suivant un préavis de trente (30) jours si
          la société considère que le Client agit ou a agi d'une façon qui
          entraîne des retombées négatives ou nuisibles pour Likewatt, ses
          prospects ou ses clients.
        </p>
        <p>
          Hormis les cas mentionnés, il ne peut être mis fin au présent Accord
          ni à l’Abonnement avant la fin de la Durée d'abonnement.
        </p>
        <h6>e) Suspension pour actes prohibés</h6>
        <p>
          Likewatt peut mettre fin au présent Accord pour juste cause de même
          qu’à l’Abonnement, sans préavis, en cas :
        </p>
        <ul>
          <li>
            d'utilisation de la Plateforme et de ses Services par le Client ou
            de ses Utilisateurs d'une manière qui porte atteinte aux lois
            nationales ou étrangères applicables ou aux règles ou termes du
            présent Accord,
          </li>
          <li>
            d'actes répétés de publication ou de chargement de contenu effectués
            par le Client ou par ses Utilisateurs, qui enfreignent de façon
            effective ou supposée les droits de propriété intellectuelle ou de
            marque d'une personne ou d'une entité.
          </li>
        </ul>
        <h6>f) Suspension pour défaut de paiement</h6>
        <p>
          Likewatt envoie toujours un avis de défaut de paiement pour toute
          somme due.
        </p>
        <p>
          À moins que la somme totale ne soit réglée, Likewatt peut mettre fin à
          l’Abonnement du Client ou suspendre son accès à la Plateforme dix (10)
          jours après l'envoi dudit avis.
        </p>
        <p>
          Likewatt ne met pas fin à l’Abonnement du Client ni ne suspend son
          accès à la Plateforme tant que le Client conteste les frais
          applicables de façon raisonnable et de bonne foi et qu'il coopère
          consciencieusement afin de résoudre le différend. Si un Service est
          suspendu pour défaut de paiement, il se peut que Likewatt facture des
          frais de réactivation afin de rétablir ledit service. 
        </p>
        <h6>g) Suspension et résiliation des Services gratuits</h6>
        <p>
          Likewatt peut suspendre, limiter ou résilier à tout moment les
          Services gratuits pour quelque motif que ce soit sans préavis. La
          société peut également résilier l'abonnement d'un Client aux Services
          gratuits pour cause d'inactivité de sa part.
        </p>
        <br />
        <h5>7. Confidentialité</h5>
        <p>
          Le Client et Likewatt s’engagent à conserver confidentielles les
          informations et documents concernant l’autre partie, de quelque nature
          qu’ils soient, financiers, techniques, sociaux ou commerciaux, qui ont
          pu être accessibles au cours de l’exécution du contrat.
        </p>
        <br />
        <h5>8. Litiges, droit applicable et juridiction compétente</h5>
        <p>
          Les présentes CGU ainsi que l’ensemble des informations contractuelles
          mentionnées sur la Plateforme sont rédigées en langue française et
          soumises à la loi française.
        </p>
        <p>
          En cas de différend ou litige entre les parties, celles-ci
          s’efforceront, de bonne foi, de parvenir à un accord amiable. 
        </p>
        <p>
          Si aucune solution amiable n’est trouvée, les tribunaux compétents du
          ressort de la Cour d’appel de Lyon seront exclusivement compétents.
        </p>
        <br />
        <h5>9. Mentions légales</h5>
        <p>
          <strong>Propriétaire et éditeur du site :</strong>
        </p>
        <p>
          La société LIKEWATT, société SAS au capital de 11 557 € immatriculée
          au registre du commerce et des sociétés de LYON sous le n°850 417 668,
          ayant son siège social au 2 Rue Coysevox, bureau 3, 69001 Lyon, prise
          en la personne de son représentant légal dûment habilité en cette
          qualité audit siège.
        </p>
        <p>
          <strong>Directeur de la publication :</strong>
        </p>
        <p>Monsieur Chady KHARRAT, gérant de la société LIKEWATT.</p>
        <p>E-mail : contact@likewatt.com</p>
        <p>
          <strong>Hébergeurs</strong>
          <strong> du site</strong>
          <strong> :</strong>
        </p>
        <p>
          La société Firebase, 188 King ST, San Francisco, CA 94107, Etats Unis
        </p>
        <p>
          <strong>Développeur du site :</strong>
        </p>
        <p>La société LIKEWATT</p>
      </div>
    </div>
  );
};

export default Terms;
