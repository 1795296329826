module.exports = {
  CONSO_DATA: "ConsoData.csv",
  WT_TMY: "windTurbineTMY.csv",
  PV_PROD: "pvPowers.csv",
  PV_TMY: "pvTMY.csv",
  WT_PROD: "windTurbinePowers.csv",
  ELECTROLYSIS_DEMAND: "h2Demand.csv",
  ECS_DEMAND: "ecsDemand.csv",
  DISTRIBKEYS: "distribKeys.csv",
};
